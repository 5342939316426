const PROJECT_ID = "ugztar6v";
const DATASET = "production";

export const updateSlugData = (changedData, theId, theData) => {
        // console.log(changedData)
        const allKeys = Object.keys(changedData)
        // console.log(changedData.title)

        const wrappedData_slug = {
            _type: "slug",
            current:changedData.slug
        };

        const wrappedData_exercept = [{
                    _key: "1786ccf444a6",
                    _type: "block",
                    children: [
                        {
                            _key: "9855681a15db",
                            _type: "span",
                            text: changedData.excerpt,
                        },
                    ],
                    markDefs:[]
        }]


	const mutations = [
		{
			patch: {
				id: theId,
				set: {},
			},
		},
	];

    for(var i =0; i < allKeys.length; i++){
        switch (allKeys[i]) {
            case 'excerpt':
                mutations[0].patch.set.excerpt = wrappedData_exercept
                break;
            
            case 'slug':
                mutations[0].patch.set.slug = wrappedData_slug
                break;
            case 'title':
                mutations[0].patch.set.title = changedData.title
                break;
        
            default:
                break;
        }

    }

    

	//Get this token from the sanity backend.
	const tokenWithWriteAccess =
		"skTSHpqak2h6vCVon1aIeXCK0mIKhtmp3A3jtffuDMm9A0nTzFzPh8pf9eL9t8rvLfMBlbNXNA0u29I5guK2zz8OvujS5JdakpRUKZGSU4YMjLoJQhIEbfO0w61KekyuevtV2W4pctWvZOhDUi0KwaoA6xNKgUqso9jnHR7rEQ83SMwOptwV";

	fetch(
		`https://${PROJECT_ID}.api.sanity.io/v2021-06-07/data/mutate/${DATASET}`,
		{
			method: "post",
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${tokenWithWriteAccess}`,
			},
			// The data need json format.
			body: JSON.stringify({ mutations }),
		}
	)
		.then((response) => response.json())
		.then((result) => console.log(result))
		.catch((error) => console.error(error));
};
