import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload, Button, message } from 'antd';
import { useState } from 'react';

import React, { useEffect, Component,useImperativeHandle }  from 'react';

import { imageUrlFor }from "../../lib/image-url.js";
import { client } from '../../containers/client.js';
import { customRequest } from '../../containers/client.js'
import {basename} from 'path'

const tokenWithWriteAccess = 'skTSHpqak2h6vCVon1aIeXCK0mIKhtmp3A3jtffuDMm9A0nTzFzPh8pf9eL9t8rvLfMBlbNXNA0u29I5guK2zz8OvujS5JdakpRUKZGSU4YMjLoJQhIEbfO0w61KekyuevtV2W4pctWvZOhDUi0KwaoA6xNKgUqso9jnHR7rEQ83SMwOptwV'
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });



const OtherImg = (props) => {
  useImperativeHandle(props.saveOther , () => {
    return {
      confirmInsert:confirmInsert
    };
  });

  const {getOtherImgStatus} = props
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([

  ]);

  const [otherImgInfo, setOtherImgInfo] = useState([])
  const [successUp, setSuccessUp] = useState([])
  const [uploading, setUploading] = useState(false)

  // 从localStorage中拿到projectID
  if (typeof window !== `undefined`) {
    var currentPrjktId =  window.localStorage.getItem('projectId')
  }
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
    // send message to father emelemt.
    // getOtherImgStatus('Other Image is changed!')
  }

  const uploadButton = (
    <div>
      <PlusOutlined/>
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  
  const test = ()=>{
    console.log('test otherimg!')
  }

  const getDetailAboutAsset= async(assetId)=>{
    await client
    .fetch(`*[_id == "${assetId}"][0]`)
    .then(asset => {
    // handle the retrieved asset here
    return(asset.originalFilename)
    })
    .catch(error => {
    // handle any errors here
    console.error(error)
    })
  }

  useEffect(()=>{
    // console.log(props.otherImgInfo)
    // 先判断other image是否为空值
    if(props.otherImgInfo.otherImages){
      const otherImgSanityUrl = props.otherImgInfo.otherImages
      // 如果不为空，那么直接将里面的link转化为正常的url链接，
      // 注意此处，other img和main img 不同，other img是数组而不是一个值！
      let otherImgUrl = []
      otherImgSanityUrl.map( (item)=>{
        // 拿到otherImg的每一个URL
        const name =  getDetailAboutAsset(item.asset._ref)
        // console.log(name)
        var everyImgUrl = imageUrlFor(item.asset._ref).url()
        // 拿到otherImg的每一个ref,在上传的时候需要
        var everyImgKey = item.asset._ref
        const uid = item._key
        otherImgUrl.push(everyImgUrl)
        //然后将它传递给fileList使其能够显示在Imgarea中。
        setFileList(current=>[
          // 到此步骤，图像可以被正确地显示在前端当中
          ...current,
            {
                  uid: uid,
                  name: everyImgKey,
                  status: 'done',
                  url: everyImgUrl,
                  uploadId: everyImgKey,
                  },
            ])
      })
      //将otherImg的转换过的链接放进State中
      setOtherImgInfo(otherImgUrl)
    }else{
      //如果other image是空值，那么fileList为空（即不设置fileList的值）
      setOtherImgInfo()
    }
  },[props.otherImgInfo])


  // 预先上传图片到图池当中
  const preUpload = async()=>{
    setUploading(true)
    // 首先获得要上传图片的信息，otherImg的长度-1为新上传的图像在当前数组中的位置
    const uplodaingImg = fileList[fileList.length - 1].originFileObj
    // 获取图片的类型
    const imgType = uplodaingImg.type

    if(imgType != "image/jpeg" & imgType != "image/png"& imgType != "image/jpg"){
      message.error("The image format must be .jpg or .png")
      handleRemove()
      setUploading(false)
      return false
    }
    // 获取图片的名字
    const imgName = uplodaingImg.name
    // 获取图片的size(可能是kb)
    const imgSize = uplodaingImg.size
    if(uplodaingImg.size >= 10000000){
      message.error("The image must be less than 10MB!")
      handleRemove()
      setUploading(false)
      return false
    }
    // 是否捕获到了新上传的otherImg，显示在下面
    // console.log("捕获到了新上传的图像在otherImg数组当中")
    // console.log("新上传的图像的名字为："+imgName+';')
    // console.log("新上传的图像的类型为："+imgType+';')
    // console.log("新上传的图像的大小为："+imgSize+'byte'+';')
    // !!!! 到此步骤上传图片到antd结束，第一阶段结束，下一阶段，将上传的图片显示在前端
    // 使用Sanity上传图片到Sanity assets(图池)中
    await client.assets.upload('image', uplodaingImg, { contentType: imgType, filename: imgName})
    .then((document)=>{
      // 注意此处是上传到图池而不是特定项目的数组中
      // console.log(document)
      // console.log(fileList)
      // uploadSuccessful(document._id)
      //移除上传状态
      handleRemove()
      console.log(document)
      //将必要的参数传递给回显函数，并且将图像显示在前端，到此步骤上传所需要的所有的准备工作全部完成。
      getImgBackAppear(document.uploadId, document.url, document.originalFilename,document._id)
      setUploading(false)
      // console.log(fileList)
    })
    .catch((error)=>{
      message.error('Image upload error', error)
      // console.log('Image upload error', error)
    })
  }

  // 将上传完成之后的upload状态移除。
  const handleRemove = () => {
    // Make a copy of the array
    const updatedArray = [...fileList];
    // Find the index of the item to remove
    // console.log(updatedArray)
    const index = updatedArray.indexOf(fileList.length - 1);
    // Remove the item from the array
    updatedArray.splice(index, 1);
    // for(var i=0; i< fileList.length; i++){
    //   if(fileList[i].status==="uploading"){
    //     fileList.splice(i,1)
    //     break;
    //   }
    // }
    // Update the state with the new array
    setFileList(updatedArray);

    // 此时打印fileList的值应该为空值
  };

  //将上传成功之后的链接放到state中
  const uploadSuccessful = (link)=>{
    setSuccessUp(
      current=>[
        ...current,
        link
      ]
    )
  }

  //   从backImg中获取图像的相关信息。
  const getImgBackAppear = (id,url,name, uploadId) =>{
    setFileList(
        current=>[
            ...current,
        {
                uid: id,
                name: name,
                status: 'done',
                url: url,
                uploadId:uploadId
                },
    ])
  }

  // 确认插入新图片到项目当中,传进来的是一个数组
  const insertArray = async(url)=>{
    //2. 添加
    if(url!=undefined){
      // console.log('开始插入副图数组.......')
      await client
      .patch(currentPrjktId)
      .append('otherImages', [{ _type: 'image', _key: Math.round(Math.random()*1000).toString(), asset: { _ref: url, } }])
      .commit({
        // Adds a `_key` attribute to array items, unique within the array, to
        // ensure it can be addressed uniquely in a real-time collaboration context
        autoGenerateArrayKeys: true,
      })
      // .then(console.log)
      .catch(console.error)
      // console.log('副图数组插入成功！')
    }else{
      // console.log("其他图片的url为空!!!")
      message.error("The image url is null, please refresh the page.")
    }
    
}

  const confirmInsert= async ()=>{
    if(fileList[0]==undefined){
      console.log('Other image is null')
      // message.error("The image list is null.")
      await client.patch(currentPrjktId).unset(['otherImages']).commit()
    }else{
      // console.log('准备清空副图数组......')
       // 注意此处是异步存取的
      //1. 删除
      await client.patch(currentPrjktId).unset(['otherImages']).commit()
      //2. 添加
      // console.log('准备更新副图......')
      await client.patch(currentPrjktId).setIfMissing({otherImages: []}).commit()
      fileList.map((item)=>{
        insertArray(item.uploadId)
      })
    }
   
  }

//id生成，生成一个随机的无序12位字符串当作id   
const timeToId=()=>{
  var date = new Date();
  var realValue = String(date.valueOf())
  var keyIdSliced = realValue.slice(0,realValue.length-1);
  return keyIdSliced
}




  return (
    <>
      <Upload
      // name='image'
        // action="https://ugztar6v.api.sanity.io/v2021-08-29/assets/images/production?filename=this.jpg"
        // headers={{
        //     'Authorization' : `Bearer ${tokenWithWriteAccess}`,
        //     'Content-Type':'image/jpeg',
        //     'Accept':'application/json',
        //     // 'responseType': 'arraybuffer' 
        // }
        // }
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        withCredentials = {true}
        // beforeUpload={beforeUpload}


        customRequest = {preUpload}
      >
        {fileList.length >= 8 ? null : uploading? null: uploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
      {/* <Button onClick={()=>{
        confirmInsert()
      }}>Confirm</Button> */}
    </>
  );
};

export default OtherImg;
