/** @format */

// Last modifoed: 1/8/2023 12:50

import React from "react";
import { graphql } from "gatsby";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import ProfilePreviewGrid from "../components/profile-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";

import Edit from "../components/edit/edit.jsx";
import Toast from "../components/utilities/Toast";

const EditPage = () => {
	return (
		<div>
			{/* When add a new page, the new content must be inside the <Layout> tag. 
			Otherwise the new content it will not display correctly.*/}
			<Layout>
				<Toast />
				<Edit />
			</Layout>
		</div>
	);
};

export default EditPage;
