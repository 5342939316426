import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload, Button, message} from 'antd';
import { useState } from 'react';

import React, { useEffect, Component, useImperativeHandle }  from 'react';
// import { withRouter } from 'react-router-dom';

import { imageUrlFor }from "../../lib/image-url.js";
import { client } from '../../containers/client.js';
import { customRequest } from '../../containers/client.js'
import {basename} from 'path'



const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


const MainImg = (props) => {
  useImperativeHandle(props.onRef, () => {
    return {
      decide: decide,
      test:test
    };
  });
  const {getMainImgStatus} = props

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [mainImgAllInfo, setMainImgAllInfo] = useState({})
  const [imgId, setimgId] = useState()
  const [removed, setRemoved] = useState()
  const [sanityImg,setSanityImg] = useState()
  const [caption,setCaption] = useState()


  
  useEffect(()=>{
    // console.log(props)
    // 首先判断主图在Sanity中是否为空值。
    if(props.mainImgInfo.mainImage){
        const imgFakeUrl = props.mainImgInfo.mainImage.asset._ref
        // console.log(imgFakeUrl)
        //  如果不为空值，那么将它通过url转码为前端可读url。
        const imgRealUrl = imageUrlFor(imgFakeUrl).url()
        // 然后将它存进state中 mainImgAllInfo。
        setMainImgAllInfo(imgRealUrl)
        //然后将它传递给fileList使其能够显示在Imgarea中。
        setFileList(current=>[
            ...current,
        {
                uid: imgRealUrl,
                name: imgFakeUrl,
                status: 'done',
                url: imgRealUrl,
                },
    ])
    setimgId(imgFakeUrl)
    setRemoved("1")
    setSanityImg("1")
    }else{
        setMainImgAllInfo()
        //  如果起初是空的，设置为0
        setRemoved('0')
        setSanityImg("0")
    }
    // 此处这个id的值只有可能变一次，即在初始的时候,这样做的结果是每一次页面重新加载之后仅渲染一次。
  },[props.mainImgInfo._id])


  useEffect(()=>{
    // Get props caption
    setCaption(props.caption)
    // console.log(props.caption)
  },[props.caption])

  // 将上传完成之后的upload状态移除。
  const handleRemove = () => {
    // Make a copy of the array
    const updatedArray = [...fileList];
    // Find the index of the item to remove
    const index = updatedArray.indexOf(fileList.length-1);
    
    // Remove the item from the array
    updatedArray.splice(index, 1);
    // Update the state with the new array
    setFileList(updatedArray);

    // 此时打印fileList的值应该为空值
  };

//   从backImg中获取图像的相关信息。
  const getImgBackAppear = (id,url,name) =>{
    setFileList(
        current=>[
            ...current,
        {
                uid: id,
                name: name,
                status: 'done',
                url: url,
                },
    ])
  }
  const test = ()=>{
    console.log('')
  }
  //此处是先上传到图池而不是图像数组当中
  const preUpload = ()=>{
    // 首先获得要上传图片的信息
    const uplodaingImg = fileList[fileList.length - 1].originFileObj
    // 获取图片的类型
    const imgType = uplodaingImg.type

    if(imgType != "image/jpeg" & imgType != "image/png"& imgType != "image/jpg"){
      message.error("The image format must be .jpg or .png")
      handleRemove()
      return false
    }

    if(uplodaingImg.size >= 10000000){
      message.error("The image must be less than 10MB!")
      handleRemove()
      return false
    }
    // 获取图片的名字
    const imgName = uplodaingImg.name
    // 获取图片的size(可能是kb)
    const imgSize = uplodaingImg.size

    // 使用Sanity上传图片到Sanity assets(图池)中
    client.assets.upload('image', uplodaingImg, { contentType: imgType, filename: imgName})
    .then((document)=>{
      // 注意此处是上传到图池而不是特定项目的数组中
      // console.log(document)
      //在上传完成之后将uploading状态从fileList中移除
      handleRemove()
      //然后将图片回显在上传的地方。不需要设置这个也可以
    //   setBackImgInfo(current=>[
    //     ...current,
    //     {
    //     assetId:document.assetId,
    //     url:document.url,
    //     name:document.originalFilename
    //     },
    // ])
      //传递参数给回显函数
      getImgBackAppear(document.assetId, document.url, document.originalFilename)
    //   设置id到state中以备插入时使用
    setimgId(document._id)
    //   insertArray(document._id)
    setRemoved("1")
    })
    .catch((error)=>{
      // console.log('Image upload error', error)
      message.error('Image upload error', error)
    })
  }

  //点击save之后插入进图片的列表中
  const insert2ImgAry = async(url)=>{
  if(url!=undefined && typeof window !== `undefined`){
    await client
    .patch(window.localStorage.getItem('projectId'))
    //注意不是数组   
    .setIfMissing({mainImage: {_type: 'mainImage', alt:caption,asset: { _ref: url, _type:'reference'}}})
    .commit()
  }else{
    message.error("The main image list is null.")
  }
  
  }

  //删除字段
  const deleteImg = async() =>{
    if (typeof window !== `undefined`) {
    await client.patch(window.localStorage.getItem('projectId')).unset(['mainImage']).commit()
    }
  }
  


//id生成，生成一个随机的无序12位字符串当作id   
  const timeToId=()=>{
    var date = new Date();
    var realValue = String(date.valueOf())
    var keyIdSliced = realValue.slice(0,realValue.length-1);
    return keyIdSliced
  }

  const decide = async ()=>{
    // console.log('click saved')
    // // 三种if分别对应三种情况
    // // 1. 本地有 sanity也有，说明是修改
    // if(removed === "1" && sanityImg ==="1"){
    //     deleteImg()
    //     insert2ImgAry(imgId)
    //     setSanityImg("1")
    // }
    // //本地没有，sanity没有，说明是删除
    // if(removed === "0" && sanityImg ==="1"){
    //     deleteImg()
    //     setSanityImg("0")
    // }
    // //本地有，sanity没有说明是增加
    // if(removed === "1" && sanityImg ==="0"){
    //     insert2ImgAry(imgId)
    //     setSanityImg("1")
    // }
    if(fileList[0]==null){
      message.error(`The project main image can not be empty!`);
      return false
    }else{
        if(imgId!=undefined){
          await deleteImg()
          await insert2ImgAry(imgId)
          // console.log('Update main image successful!')
          return true
        }else{
          // console.log('Please refresh the page!')
          message.error("please refresh the page.")
        }
        
    }

  }

  
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleChange = ({ fileList: newFileList }) => {
      setFileList(newFileList);
      getMainImgStatus('Main image changed')
  } 
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove = {()=>{
            setRemoved('0')
          }}
        customRequest = {preUpload}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
        
      </Modal>
      {/* <Button type='primary' onClick={()=>{
        decide()
        }}>Save</Button> */}
    </>
  );
};
export default MainImg;
// export default withRouter(MainImg);