import React from 'react';
import moment from 'moment'

const TimeTransfer = (time) => (
    moment(new Date(time)).format('YYYY-MM-DD HH:mm:ss')
);
export default TimeTransfer;









