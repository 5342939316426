import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload, Button, message, ConfigProvider} from 'antd';
import { useState } from 'react';
import React from "react";
import { useEffect,useImperativeHandle } from "react";
import { client } from '../../containers/client.js';
import { UploadOutlined,  InboxOutlined} from '@ant-design/icons';

const { Dragger } = Upload;
const tokenWithWriteAccess = 'skTSHpqak2h6vCVon1aIeXCK0mIKhtmp3A3jtffuDMm9A0nTzFzPh8pf9eL9t8rvLfMBlbNXNA0u29I5guK2zz8OvujS5JdakpRUKZGSU4YMjLoJQhIEbfO0w61KekyuevtV2W4pctWvZOhDUi0KwaoA6xNKgUqso9jnHR7rEQ83SMwOptwV'


const AddPdfTest = (props) => {

    useImperativeHandle(props.pdfSave, () => {
        return {
            insertPdf:insertPdf,
        };
      });
  const {getPDFStatus} = props
  const [fileList, setFileList] = useState([]);
  const [pdfLink, setPdfLink] = useState('')
  const [pdfId, setPdfId] = useState('')



  useEffect(()=>{
    if(props.alldata.pdf!=undefined){
        // console.log(props.alldata.pdf)
        const thePdf = props.alldata.pdf.asset._ref
        const pdfSuffix = thePdf.split('-')[1]+'.pdf'
        const pdfLink = 'https://cdn.sanity.io/files/ugztar6v/production/'+pdfSuffix
        setFileList(current=>
            [
                ...current,
            {
                uid: thePdf,
                name: thePdf,
                status: 'done',
                url: pdfLink,

            }])
        // 执行查询获得名字
        const query = `*[_type == "file"]`
        // const params = {minSeats: 2}

        client.fetch(query).then((doc) => {
            console.log(doc)
        })

    }
    }, [props.alldata._id])

  const handleChange = (info) => {
    const {status} = info.file
    getPDFStatus('PDF changed!')
    if(info.file.type != "application/pdf"){
        message.error(`The file must be PDF format!`);
    }else{

    // 上传到图池成功之后获得后端返回的收据
    if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
        if(info.file.response!=undefined){
            const pdfIds = info.file.response.document._id
            setPdfId(pdfIds)
        }
        
    }
    if(info.fileList[0]==undefined){
        setPdfId('')
    }
    if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
    } 
    else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
    }




    let newFileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    newFileList = newFileList.slice(-2);

    // 2. Read from response and show file link
    newFileList = newFileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(newFileList);
    }
  };

//   上传pdf的链接
  const upload = {
    action: `https://ugztar6v.api.sanity.io/v1/assets/files/production?filename=${pdfLink}`,
    headers:{
        Authorization : `Bearer ${tokenWithWriteAccess}`
    },
    onChange: handleChange,
    maxCount:1
  };


//   在上传之前拿到info，即pdf的基本信息。
  const getPdf = (info)=>{
    setPdfLink(info.name)
  }

  //删除字段
  
  const deleteImg = async() =>{
    if (typeof window !== `undefined`) {
      await client.patch(window.localStorage.getItem('projectId')).unset(['pdf']).commit()
    }
  }

  const insertPdf = async()=>{
    if(pdfId===''){
        // console.log('pdf为空')
        await deleteImg()
    }else{
        await deleteImg()
        await insert2Pdf(pdfId)
    }
    
  }

  const insert2Pdf = async(url)=>{
    if(url!=undefined && typeof window!==`undefined`){
        // console.log('准备插入pdf文档...')
      await client
      .patch(window.localStorage.getItem('projectId'))
      //注意不是数组   
      .setIfMissing({pdf: {_type: 'file', asset: { _ref: url, _type:'reference'}}})
      .commit()
    }else{
      console.log("pdf URL is null")
    }
    
    }
  return (
    <div>
        <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#000000',
      },
    }}
  >
     <Dragger {...upload} fileList={fileList} beforeUpload = {getPdf} >
        <p className="ant-upload-drag-icon">
      <InboxOutlined />
        </p>
      <p>Click or drag file to this area to upload PDF</p>
    </Dragger>
  </ConfigProvider>



       
    {/* <button onClick={insertPdf}>Submit</button> */}
    </div>
    
    
  );
};
export default AddPdfTest;