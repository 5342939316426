/** @format */

import React from "react";
import "./edit.css";
import { useState } from "react";
import { useEffect } from "react";
import { updateSlugData } from "../updateSlug";
import TimePickerAntd from "./timePicker.jsx";
import { client } from "../../containers/client.js";
import MainImg from "./mainImg.jsx";
import OtherImg from "./otherImg";
import AddPdfTest from "./addPdfTest";
import { message } from "antd";
import { navigate } from "gatsby";
import { checkSlug } from "./checkSlug";
import { generateSlug } from "./generateSlug";
import { Popup } from "../utilities/Popup";
import { Button, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
const { confirm } = Modal;

const Edit = () => {
	// Use child function.
	let ChildRef = React.createRef();
	let OtherImgSave = React.createRef();
	let savePDF = React.createRef();

	var isClick = true;

	const [isLoading, setIsLoading] = useState(true);
	// Can not be useState()
	const [projects, setProjects] = useState([]);
	const [projectsNoMember, setProjectsNoMember] = useState([]);
	const [titlet, setTitle] = useState("Click to edit");
	const [theFilledData, setChangeData] = useState({});
	const [mediaData, setMediaData] = useState({});
	const [fileList, setFileList] = useState({});
	const [originalTime, setOriginalTime] = useState();
	const [caption, setCaption] = useState();
	const [link, setLink] = useState("");
	const [saveStatus, setSaveStatus] = useState("Save");
	const [saveStatusMedia, setSaveStatusMedia] = useState("Save");
	// save button control
	// const [btnSvStyle, setBtnSvStyle] = useState("")
	const [btnDisabledElement, setDisabledElement] = useState(false);
	const [stylebtn, setStyleBtn] = useState("red");
	const [slug, setSlug] = useState("");

	// after saving loading this message.
	const [messageApi, contextHolder] = message.useMessage();
	const success = () => {
		messageApi
			.open({
				type: "loading",
				content: "Saving...",
				duration: 2.5,
			})
			.then(() => message.success("Saved", 2.5));
	};

	function beforeunload(e) {
		let confirmationMessage = "你确定离开此页面吗?";
		(e || window.event).returnValue = confirmationMessage;
		return confirmationMessage;
	}

	useEffect(() => {
		return () => {
			window.removeEventListener("beforeunload", beforeunload);
			// 这里写离开页面的后续操作
			// 项目中这个页面使用了websocket。所以在页面离开的时候，需要将websocket关闭掉
			// 关闭websocket
			window.close();
		};
	}, []);

	useEffect(() => {
		// 拦截判断是否离开当前页面
		window.addEventListener("beforeunload", beforeunload);
	}, []);

	// useEffect(()=>{
	// 	alert('a')
	// },[stylebtn])

	var imgFileList = {};
	const tableName = "project";
	let PROJECT_ID = "ugztar6v";
	let DATASET = "production";

	let QUERY = encodeURIComponent(`*[_type == "${tableName}"]`);
	let URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;
	const tokenWithWriteAccess =
		"skTSHpqak2h6vCVon1aIeXCK0mIKhtmp3A3jtffuDMm9A0nTzFzPh8pf9eL9t8rvLfMBlbNXNA0u29I5guK2zz8OvujS5JdakpRUKZGSU4YMjLoJQhIEbfO0w61KekyuevtV2W4pctWvZOhDUi0KwaoA6xNKgUqso9jnHR7rEQ83SMwOptwV";

	if (typeof window !== `undefined`) {
		var projectId = window.localStorage.getItem("projectId");
		// console.log("projectId是" + projectId);
	}

	const handleChange = (value, attrStr) => {
		// when data in input is changed, this function will be trigger

		setChangeData({
			...theFilledData,
			[attrStr]: value,
		});

		// console.log(theFilledData)
		setSaveStatus("Save");
	};

	//Send this function to child time picker element to get time value.
	const getChildCount = (val) => {
		// console.log(val);
		//Save changed time into state.
		setOriginalTime(val);
		// Change save Status
		setSaveStatus("Save");
	};

	// Send it to main image to get the time value
	const getMainImageStatus = (val) => {
		// console.log(val);
		setSaveStatusMedia("Save");
	};

	// Send it to other image to get the time value
	const getOtherImageStatus = (val) => {
		// console.log(val);
		setSaveStatusMedia("Save");
	};

	// Send it to other image to get the time value
	const getPDFStatus = (val) => {
		// console.log(val);
		setSaveStatusMedia("Save");
	};

	const mediaChange = (value, attrStr) => {
		// when data in input is changed, this function will be trigger
		setMediaData({
			...mediaData,
			[attrStr]: value,
		});
		// console.log(mediaData);
	};

	//Update link into state.
	const updateLink = (value) => {
		setLink(value);
		// set save status
		setSaveStatusMedia("Save");
	};

	useEffect(() => {
		const initialFunc = async () => {
			if (typeof window !== `undefined`) {
				var USEREMAIL = window.localStorage.getItem("email");
			}

			if (!USEREMAIL && typeof window !== `undefined`) {
				alert("You need to login");
				navigate("/login", { replace: true });
			} else {
				await fetch(URL)
					.then((res) => res.json())
					.then(({ result }) => {
						// console.log(result);
						result.map((item) => {
							if (item._id == projectId) {
								setProjects(item);
								setTitle(item.title);
								setSlug(item.slug?.current);
								// setChangeData({
								// 	...theFilledData,
								// 	["slug"]: item.slug?.current,
								// });
								// 设置时间的值
								setIsLoading(false);
								// Save the orignal time into state.
								setOriginalTime(item.publishedAt);
								if (item.mainImage) {
									var mainURL = item.mainImage.asset._ref;
								}
								if (item.mainImage) {
									setCaption(item.mainImage.alt);
								}
								// console.log(item.otherImages);
								var otherImgList = [];
								// 判断有没有otherImage
								if (item.otherImages) {
									item.otherImages.map((otherImg) => {
										otherImgList.push(otherImg.asset._ref);
									});
								}

								setFileList({
									mainImageURL: mainURL,
									otherImgURL: otherImgList,
								});
							}
						});
					});
			}
		};

		initialFunc();
	}, []);

	//  Only insert the changed data rather than all.
	const updateData = () => {
		const checkBtn = handleBtn();
		if (!checkBtn) {
			return 0;
		}

		if (typeof window !== `undefined`) {
			messageApi.open({
				type: "loading",
				content: "Saving project data....",
				duration: 0,
			});

			// Save text data.
			updateSlugData(theFilledData, projectId, theFilledData.slug);
			// Save date time.--------------------------------------------------
			client
				.patch(window.localStorage.getItem("projectId"))
				.setIfMissing({ publishedAt: "" })
				.set({ publishedAt: originalTime })
				.commit()
				// destroy saving icon
				.then(() => {
					messageApi.destroy();
				})
				.then(() => {
					setSaveStatus("Saved");
				})
				.then(() => message.success("Saved", 2.5));
		}
	};

	const changeCaption = (value) => {
		setCaption(value);
		// set Save status
		setSaveStatusMedia("Save");
	};

	const saveMedia = async () => {
		// Set button can not click when saving.
		// Prevent click fast.
		const checkBtn = handleBtn();
		if (!checkBtn) {
			return 0;
		}

		messageApi.open({
			type: "loading",
			content: "Saving media data...",
			duration: 0,
		});
		// Save main image.
		if (await ChildRef.current.decide()) {
			// Save other images.
			// setStyleBtn("green")
			try {
				await OtherImgSave.current.confirmInsert();
				//Save link.
				await client
					.patch(localStorage.getItem("projectId"))
					// .setIfMissing({ video: "" })
					.set({ videoURL: link })
					.commit();
				// Save PDF
				await savePDF.current
					.insertPdf()
					// destroy saving icon
					.then(() => {
						messageApi.destroy();
					})
					.then(() => {
						setSaveStatusMedia("Saved");
					})
					.then(() => message.success("Saved", 2.5));
			} catch (error) {
				// alert("Oops, something went wrong, please refresh and retry.")
				messageApi.destroy();
				message.error(
					"Oops, something went wrong, please refresh and retry"
				);
				throw "There are something about the confirmInsert function in edit.jsx";
			}
		} else {
			messageApi.destroy();
		}
	};

	const deleteProject = () => {
		if (typeof window !== `undefined`) {
			var ask = window.confirm(
				"Do you really want to delete this project? This is irreversible!"
			);

			if (ask == true) {
				client
					.delete(projectId)
					.then(() => {
						alert("Delete success");
						// <Popup value = {'aaaa'}/>
						navigate("/selfprofile");
					})
					.catch((err) => {
						message.error("Delete failed: ", err.message);
						// console.error("Delete failed: ", err.message);
					});
			}
		}
	};

	const showDeleteConfirm = () => {
		confirm({
			title: "Are you sure delete this project?",
			icon: <ExclamationCircleFilled />,
			content: "This is irreversible!",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk() {
				client
					.delete(projectId)
					.then(() => {
						message.success("Delete success");
						// <Popup value = {'aaaa'}/>
						navigate("/selfprofile");
					})
					.catch((err) => {
						// console.error("Delete failed: ", err.message);
						message.error("Delete failed: ", err.message);
					});
			},
			onCancel() {
				// console.log('Cancel');
			},
		});
	};

	const handleBtn = () => {
		if (isClick) {
			isClick = false;
			//事件
			//定时器
			setTimeout(function () {
				isClick = true;
			}, 8000); //8秒内不能重复点击
			//执行代码块
			return true;
		} else {
			message.warning("Please wait few seconds and retry ....");
			return false;
		}
	};

	const clearDefaultTitle = (text) => {
		if (text === "Click here to edit") {
			setTitle("");
		}
	};

	return (
		<div className="editWrapper">
			{contextHolder}
			<div className="left-column">
				<div className="click-title">
					<textarea
						className="title-input"
						type="text"
						defaultValue={
							// isLoading
							// 	? ""
							// 	: projects.title != null
							// 		? projects.title
							// 		: "Click here to edit"
							isLoading
								? ""
								: titlet != undefined
								? titlet
								: "Click here to edit"
						}
						onChange={(e) => {
							handleChange(e.target.value, "title");
							setTitle(e.target.value);
						}}
						onClick={(e) => {
							clearDefaultTitle(e.target.value);
						}}
					/>
				</div>
				<div className="projectDes">
					<div className="pjtName">
						Project Description{" "}
						<span style={{ color: "red" }}> *</span>
					</div>
					<textarea
						name=""
						className="pdes"
						id=""
						cols="30"
						rows="10"
						defaultValue={
							isLoading
								? ""
								: projects.excerpt
								? projects.excerpt[0].children[0].text
								: ""
						}
						onChange={(e) => {
							handleChange(e.target.value, "excerpt");
						}}
					></textarea>
				</div>

				<div className="year">
					<div className="pjtTime">
						Year <span style={{ color: "red" }}> *</span>
					</div>
					<TimePickerAntd
						currentTime={projects}
						// Get the time value from the child.
						getSearchState={getChildCount}
					/>
				</div>

				<div className="slug">
					<div className="pjtSlug">
						Customise project URL
						<span style={{ color: "red" }}> *</span>
					</div>
					<div className="slug-input-and-btn">
						{/* Test the return value have key field or not, if not, return '' */}
						<input
							type="text"
							className="slug-input"
							placeholder="your-project-name"
							id="italicPlaceholder"
							defaultValue={
								// isLoading ? "": projects.slug?.current
								slug
							}
							value={slug}
							onChange={(e) => {
								handleChange(e.target.value, "slug");
								setSlug(e.target.value);
							}}
						/>

						<button
							className="genSlug"
							onClick={async () => {
								let newSlug = await generateSlug(
									projectId,
									titlet,
									theFilledData.slug
								);
								// console.log(newSlug);
								// console.log(theFilledData.slug);
								setChangeData({
									...theFilledData,
									["slug"]: newSlug,
								});

								setSlug(newSlug);
								setSaveStatus("Save");
								// console.log(theFilledData.slug);
							}}
						>
							Generate
						</button>
					</div>
				</div>

				<div className="buttonClass">
					<button
						className="saveBtn"
						onClick={showDeleteConfirm}
						style={{ paddingLeft: "20px", paddingRight: "20px", fontStyle:'italic' }}
					>
						Delete Project
					</button>

					<button
						className="saveBtn"
						onClick={async () => {
							if (slug === "") {
								message.error("Please fill the slug");
								return 0;
							}
							if (
								await checkSlug(projectId, theFilledData.slug)
							) {
								updateData();
							} else {
								message.error(
									"Slug must be unique, try another one"
								);
							}
						}}
					>
						{saveStatus}
					</button>
				</div>
			</div>

			<div className="midone"></div>

			<div className="right-column">
				<div className="media">Media</div>
				<div>
					<div className="thumbnail">
						Hero Image (.png or .jpg){" "}
						<span style={{ color: "red" }}> *</span>
					</div>
					<div className="tubmbnail-content">
						<MainImg
							mainImgInfo={projects}
							caption={caption}
							onRef={ChildRef}
							getMainImgStatus={getMainImageStatus}
						/>
					</div>
				</div>

				<div className="ImagesText">
					Image Album (.png or .jpg, maximum 10 MB per image)
					<div style={{ border: "1px solid black", padding: "10px" }}>
						<OtherImg
							otherImgInfo={projects}
							saveOther={OtherImgSave}
							getOtherImgStatus={getOtherImageStatus}
						/>
					</div>
				</div>

				<div>
					<div className="caption">Caption</div>
					<textarea
						className="body-text"
						style={{ height: "100px" }}
						defaultValue={
							isLoading
								? ""
								: projects.mainImage
								? projects.mainImage.alt
								: ""
						}
						onChange={(e) => {
							changeCaption(e.target.value);
						}}
					></textarea>
				</div>

				<div>
					<div className="VideoText">Video (paste code here)</div>

					<textarea
						className="body-text"
						defaultValue={isLoading ? "" : projects.videoURL}
						style={{ height: "100px" }}
						// onChange = {(e)=>{mediaChange(e.target.value, 'video')}}
						onChange={(e) => {
							updateLink(e.target.value);
						}}
					></textarea>
				</div>

				<div>
					<div className="pdf" style={{ textAlign: "left" }}>
						Add PDF
					</div>
					<div className="pefUpload">
						<AddPdfTest
							alldata={projects}
							pdfSave={savePDF}
							getPDFStatus={getPDFStatus}
						/>
					</div>
				</div>

				<div className="buttonClass" style={{ marginLeft: "auto" }}>
					<button
						className="saveBtn"
						onClick={saveMedia}
						style={{ marginLeft: "auto" }}
					>
						{saveStatusMedia}
					</button>
				</div>
			</div>
		</div>
	);
};

export default Edit;
