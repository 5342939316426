import React from 'react';
import { DatePicker, Space, ConfigProvider } from 'antd';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState,useRef } from 'react';
import TimeTransfer from './transferTimeFormat';


const format = 'HH:mm';
const { RangePicker } = DatePicker;
const onOk = (value) => {
  console.log('onOk: ', value);
};
const TimePickerAntd = (props) => {
  const [currentDate, setCurrentDate] = useState('')
  const [isLoading, setIsLoading] = useState(true);
  const [datePart, setPartDate] = useState()
  const countRef = useRef(null)
  const { getSearchState } = props;


  useEffect(()=>{
    const createdDate = props.currentTime.publishedAt
    const realDate = TimeTransfer(createdDate)
    const justDate = String(realDate).slice(0,10)
    //把日期存进去。
    setCurrentDate(dayjs(justDate,"YYYY-MM-DD"))
    setIsLoading(false)
  },[props.currentTime])

  const onChange = (value, dateString) => {
    if(dateString!=''){
      // 此处必须存储整个obj要不然在清空日期的时候会报错。
      let d = new Date(Date.parse(dateString+"T00:00:00"));
      const sanityTime = d.toISOString()
      console.log(d.toISOString())
      setCurrentDate(dayjs(dateString,"YYYY-MM-DD"))
      // Send Time to Father elements.
      getSearchState(d.toISOString())
      setCurrentDate(dayjs(dateString,"YYYY-MM-DD"))
    }else{
      //如果没有dateString就显示为空。
      setCurrentDate('')
    }
  };


  

  return(
    <div className = 'timePickerWrapper' style = {{marginTop:'10px'}}>
      {/* Change the primary color of this component. */}
      <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#000000',
      },
    }}
  >
    <Space direction="vertical" size={12} style = {{}} >
          {/* This part can not use defaultValue,otherwise can not get the real value from State. */}
            <DatePicker value={currentDate} onChange={onChange} onOk={onOk} />
        </Space>
  </ConfigProvider>
        
    </div>
  )
  };
export default TimePickerAntd;








